@use 'variables';

.color-disabled {
  path {
    fill: variables.$color-disabled;
  }
}

.d-block {
  display: block;
}

.text-align-end {
  text-align: end;
}

.one-error {
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-bold);
  line-height: variables.$default-line-height;
  text-align: center;

  a {
    text-decoration: underline;
  }
}

.dot-separator::after {
  content: ' • ';
  padding: 0 5px;
  font-size: 20px;
}
