/* stylelint-disable selector-class-pattern */
.mat-mdc-snack-bar-container {
  &.snack-bar-error {
    --mdc-snackbar-container-color: #f6c1b0;
    --mat-mdc-snack-bar-button-color: variables.$color-dark-gray;
    --mdc-snackbar-supporting-text-color: variables.$color-dark-gray;

    font-size: var(--font-size-2);
    font-weight: var(--font-weight-medium);
    line-height: 26px;

    .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
      --mat-text-button-state-layer-color: transparent;
      --mat-snack-bar-button-color: variables.$color-dark-gray;
    }
  }

  &.snack-bar-success {
    --mdc-snackbar-container-color: #e1eded;
    --mat-mdc-snack-bar-button-color: variables.$color-dark-gray;
    --mdc-snackbar-supporting-text-color: variables.$color-dark-gray;

    font-size: var(--font-size-2);
    font-weight: var(--font-weight-medium);
    line-height: 26px;

    .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
      --mat-text-button-state-layer-color: transparent;
    }
  }
}
