$font-family: 'EONBrixSans', arial, geneva, helvetica, sans-serif;

* {
  font-family: $font-family;
}

@mixin base() {
  @content;

  --space-0: 0;
  --space-1: 2px;
  --space-2: 4px;
  --space-3: 8px;
  --space-4: 12px;
  --space-5: 16px;
  --space-6: 24px;
  --space-7: 32px;
  --space-8: 40px;
  --space-9: 48px;
  --space-10: 56px;
  --space-11: 64px;
  --space-12: 72px;
  --space-13: 80px;
  --space-14: 96px;
  --space-15: 120px;
  --space-16: 160px;

  --font-size-1: 12px;
  --font-size-2: 14px;
  --font-size-3: 16px;
  --font-size-4: 18px;
  --font-size-5: 20px;
  --font-size-6: 24px;
  --font-size-7: 32px;
  --font-size-8: 40px;
  --font-size-9: 48px;
  --font-size-10: 64px;

  --font-weight: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-weight-black: 900;

  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 12px;
  --border-radius-full: 9999px;

  --shadow-sm: 0 1px 4px rgba(0, 0, 0, 8%);
  --shadow-md: 0 2px 12px rgba(0, 0, 0, 12%);
  --shadow-lg: 00 4px 20px rgba(0, 0, 0, 16%);

  --transition: 0.3s ease;

  --z-index-1: 100;
  --z-index-2: 200;
  --z-index-3: 300;
  --z-index-4: 400;
  --z-index-5: 500;
  --z-index-max: 9999;
}
