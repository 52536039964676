@use 'variables';

h1 {
  font-size: var(--font-size-8);
  font-weight: var(--font-weight-black);
  line-height: var(--space-8);
  color: variables.$color-dark-gray;
}

h2 {
  font-size: var(--font-size-6);
  font-weight: var(--font-weight-bold);
  line-height: 1.75rem;
  color: variables.$color-dark-gray;
}

h3 {
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-bold);
  line-height: variables.$default-line-height;
  color: variables.$color-dark-gray;
}

h4 {
  font-size: var(--font-size-6);
  font-weight: var(--font-weight-black);
  line-height: var(--space-6);
  color: variables.$color-dark-gray;
}

h5 {
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-bold);
  line-height: var(--space-6);
  color: variables.$color-dark-gray;
}
