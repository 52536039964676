@use 'variables';

$difference-between-breakpoints: 0.02;

.tabs-header {
  padding: 0 variables.$default-padding !important;
}

.tab-content {
  padding: 0 !important;

  &:has(.pdf-viewer-wrapper) {
    @container (max-width: #{variables.$cdk-breakpoint-xsmall-max-width + $difference-between-breakpoints}) {
      .download-button {
        display: flex;
      }
    }
  }
}
