/**
* Source:
* https://www.figma.com/design/2XYs8pcbtTsjTA8Q8tTwLk/ONE-Portal-%7C%C2%A0for-DSO?node-id=59553-32165&t=Yr6ZyWwfUdKG7eVH-0
*/
$content-max-width: 1116px;
$navigation-height: 64px;
$content-margin-top: 20px;
$side-navigation-margin-top: $navigation-height + $content-margin-top;
$color-light-gray: #dcdcdc;
$color-gray: #cccbca;
$color-dark-gray: #262626;
$color-disabled: #999;
$color-primary: #1ea2b1;
$color-red-error: #b00402;

/** Source for color codes:
* https://brand.eon.com/document/256#/design-essentials/colour
*/
$eon-light-overlay: #f6f6f7;

/**
* Source:
* https://material.angular.io/cdk/layout/overview#predefined-breakpoints
*/
$cdk-breakpoint-xsmall-min-width: 400px; // custom
$cdk-breakpoint-xsmall-max-width: 599.98px;
$cdk-breakpoint-small-min-width: 600px;
$cdk-breakpoint-small-max-width: 959.98px;
$cdk-breakpoint-medium-min-width: 960px;
$cdk-breakpoint-medium-max-width: 1279.98px;

/**
* Source: https://eon.enterprise.slack.com/archives/C071MQD904R/p1725455745205189
*/
$default-padding: 1.25rem;

/**
* Source: https://eon.enterprise.slack.com/archives/C071MQD904R/p1725456435891479
*/
$default-line-height: 1.5rem;
$icon-size-default: 1.25rem;
$icon-size-small: 1rem;
$icon-size-big: 2rem;
