@use 'variables';

:root {
  --mat-datepicker-calendar-container-background-color: var(--color-white);
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  padding-right: max(
    16px,
    var(
        --mdc-outlined-text-field-container-shape,
        var(--mat-app-corner-extra-small)
      ) + 4px
  ) !important;
}
