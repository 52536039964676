@use 'variables';

:root {
  --mdc-dialog-container-shape: 19px;
}

.dialog {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
  }

  &__headline {
    font-size: var(--font-size-6);
    font-weight: var(--font-weight-black);
    line-height: var(--space-6);
  }

  &__lead {
    padding: 15px 0;

    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: variables.$color-dark-gray;
  }

  &__slider {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 5px 0;

    &__text {
      padding-left: 5px;
      font-size: var(--font-size-3);
      font-weight: var(--font-weight);
      line-height: variables.$default-line-height;
    }
  }

  mat-dialog-content {
    min-height: 200px;
    padding: 10px 30px 0 !important;
  }

  mat-dialog-actions {
    gap: 20px;
    padding: 30px;
  }
}
