@use './select';
@use './dialog';
@use './snack-bar';
@use './select-toggle';
@use './input';
@use './table';
@use './date-range-picker';

:root {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--color-primary);
  --mdc-outlined-text-field-focus-outline-color: var(--color-primary);
  --mat-select-panel-background-color: var(--color-white);
  --mdc-outlined-text-field-outline-color: var(--color-gray-strong);
  --mdc-linear-progress-active-indicator-color: var(--color-primary);
  --mdc-circular-progress-active-indicator-color: var(--color-primary);
  --mat-form-field-container-height: 33px;
  --mat-table-header-headline-size: 1rem;
  --mat-table-row-item-label-text-size: 1rem;
  --mat-table-background-color: transparent;
  --mat-table-header-headline-color: #262626;
  --mat-table-row-item-label-text-color: #262626;
  --mat-paginator-container-background-color: transparent;
  --mat-paginator-container-text-size: 1rem;
  --mat-paginator-container-text-tracking: 0.025rem;
  --mat-paginator-select-trigger-text-size: 1rem;
  --mdc-plain-tooltip-supporting-text-color: var(--color-white);
  --mdc-fab-small-container-elevation-shadow: 0;
  --mdc-fab-small-hover-container-elevation-shadow: 0;
  --mdc-fab-small-focus-container-elevation-shadow: 0;
  --mdc-fab-small-pressed-container-elevation-shadow: 0;

  /*  TODO: align it with designs once UI library's color usage is fixed */
  --mdc-plain-tooltip-container-color: #39393a;

  /*  TODO: align it with designs once UI library's color usage is fixed */
  --mdc-outlined-text-field-label-text-color: #757575;
}

.cdk-overlay-container {
  z-index: 9999 !important;
}

.table-filter {
  &--text-ellipsis {
    .mdc-list-item__primary-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
