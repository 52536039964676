@import './reset';
@import '@angular/cdk/overlay-prebuilt.css';

@import './base';
@import './themes';
@import './components';
@import './typography';
@import './utils';

@mixin html() {
  // Default html element styles
}

@mixin body() {
  // Default body element styles
  padding: 0;
  margin: 64px 0 0 0;
  background-color: var(--color-lightgray);

  &.noscroll {
    overflow: hidden;
  }
}
