/* stylelint-disable scss/dollar-variable-pattern */
// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use 'sass:color';
@use '@angular/material' as mat;

// Note: Color palettes are generated from primary: #1ea2b1
$primary-color: #1ea2b1;
$_palettes: (
  primary: (
    0: #000,
    10: color.scale($primary-color, $lightness: - calc(100% / 80) * 70),
    20: color.scale($primary-color, $lightness: - calc(100% / 80) * 60),
    25: color.scale($primary-color, $lightness: - calc(100% / 80) * 55),
    30: color.scale($primary-color, $lightness: - calc(100% / 80) * 50),
    35: color.scale($primary-color, $lightness: - calc(100% / 80) * 45),
    40: color.scale($primary-color, $lightness: - calc(100% / 80) * 40),
    50: color.scale($primary-color, $lightness: - calc(100% / 80) * 30),
    60: color.scale($primary-color, $lightness: - calc(100% / 80) * 20),
    70: color.scale($primary-color, $lightness: - calc(100% / 80) * 10),
    80: $primary-color,
    90: color.scale($primary-color, $lightness: calc(100% / 20) * 10),
    95: color.scale($primary-color, $lightness: calc(100% / 20) * 15),
    98: color.scale($primary-color, $lightness: calc(100% / 20) * 18),
    99: color.scale($primary-color, $lightness: calc(100% / 20) * 19),
    100: #fff,
  ),
  secondary: (
    0: #000,
    10: #051f22,
    20: #1c3438,
    25: #273f43,
    30: #334b4e,
    35: #3e565a,
    40: #4a6266,
    50: #627b7f,
    60: #7c9599,
    70: #96b0b4,
    80: #b1cbd0,
    90: #cde7ec,
    95: #dbf6fa,
    98: #ecfcff,
    99: #f6feff,
    100: #fff,
  ),
  tertiary: (
    0: #000,
    10: #0d1b37,
    20: #23304d,
    25: #2f3b58,
    30: #3a4664,
    35: #465271,
    40: #525e7d,
    50: #6a7797,
    60: #8490b2,
    70: #9eabce,
    80: #bac6ea,
    90: #d9e2ff,
    95: #eef0ff,
    98: #faf8ff,
    99: #fefbff,
    100: #fff,
  ),
  neutral: (
    0: #000,
    10: #191c1d,
    20: #2e3132,
    25: #393c3d,
    30: #444748,
    35: #505353,
    40: #5c5f5f,
    50: #747878,
    60: #8e9192,
    70: #a9acac,
    80: #c4c7c7,
    90: #e1e3e3,
    95: #eff1f1,
    98: #f8fafa,
    99: #fafdfd,
    100: #fff,
    4: #0b0f0f,
    6: #101415,
    12: #1d2021,
    17: #272b2b,
    22: #323536,
    24: #363a3a,
    87: #d8dadb,
    92: #e6e8e9,
    94: #eceeee,
    96: #f2f4f4,
  ),
  neutral-variant: (
    0: #000,
    10: #141d1f,
    20: #293233,
    25: #343d3f,
    30: #3f484a,
    35: #4b5456,
    40: #576062,
    50: #6f797a,
    60: #899294,
    70: #a3adaf,
    80: #bfc8ca,
    90: #dbe4e6,
    95: #e9f2f4,
    98: #f1fbfd,
    99: #f6feff,
    100: #fff,
  ),
  error: (
    0: #000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #fff,
  ),
);
$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes, neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);
$light-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: $_primary,
      tertiary: $_tertiary,
    ),
    density: (
      scale: -5,
    ),
  )
);
