.one-list {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  min-width: 140px;
  list-style: none;
}

.one-list-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 0;
  background-color: #fff;
  cursor: pointer;

  &.with-padding {
    padding: 12px 16px;
  }

  &:hover {
    background-color: #f5f5f5;
  }
}
