// Headings
.one-h1 {
  font-size: var(--font-size-10);
  font-weight: var(--font-weight-black);
}

.one-h2 {
  font-size: var(--font-size-9);
  font-weight: var(--font-weight-black);
}

.one-h3 {
  font-size: var(--font-size-6);
  font-weight: var(--font-weight-bold);
}

// Text Sizes
.one-text-small {
  font-size: var(--font-size-1);
}

.one-text-medium {
  font-size: var(--font-size-3);
}

.one-text-large {
  font-size: var(--font-size-4);
}

// Font Weights
.one-font-light {
  font-weight: var(--font-weight);
}

.one-font-medium {
  font-weight: var(--font-weight-medium);
}

.one-font-bold {
  font-weight: var(--font-weight-bold);
}

.one-font-black {
  font-weight: var(--font-weight-black);
}
