@use 'variables';

$border-radius: 20px;
$border-color: variables.$color-dark-gray;
$border-width: 2px;
$switch-handle-size: 10px;

.mat-mdc-slide-toggle {
  @mixin slide-toggle-theme-colors($colorClass, $color) {
    &.#{$colorClass} {
      .mdc-switch:hover {
        border: $border-width solid $color !important;
      }

      .mdc-switch--selected {
        &.mdc-switch--checked {
          border: $border-width solid $color !important;
        }

        .mdc-switch__handle::after {
          background: $color !important;
        }
      }

      .mdc-switch--selected:enabled:not(:focus, :active) {
        .mdc-switch__handle::after {
          background: $color !important;
        }
      }

      .mdc-switch--unselected:enabled:hover:not(:focus, :active) {
        .mdc-switch__handle::after {
          background: $border-color !important;
        }
      }

      .mdc-switch--unselected:enabled:active {
        .mdc-switch__handle::after {
          background: $border-color !important;
        }
      }

      .mdc-switch--unselected:enabled:focus:not(:active) {
        .mdc-switch__handle::after {
          background: $border-color !important;
        }
      }

      .mdc-switch--selected:enabled:active {
        .mdc-switch__handle::after {
          background: $color !important;
        }
      }

      .mdc-switch--selected:enabled:focus:not(:active) {
        .mdc-switch__handle::after {
          background: $color !important;
        }
      }
    }
  }

  .mdc-switch__track,
  .mdc-switch__icons {
    display: none !important;
  }

  .mdc-switch__handle:has(.mdc-switch__icons) {
    --mat-switch-with-icon-handle-size: #{$switch-handle-size};
  }

  .mdc-switch {
    width: 30px !important;
    height: 20px !important;
    border: $border-width solid $border-color;
    border-radius: $border-radius !important;

    .mdc-switch__ripple {
      display: none;
    }

    &.mdc-switch--selected {
      .mdc-switch__handle:has(.mdc-switch__icons) {
        margin-left: 14px;
      }
    }

    &.mdc-switch--unselected {
      .mdc-switch__handle-track {
        .mdc-switch__handle {
          margin-left: 2px;
        }
      }
    }
  }

  .mdc-switch:active:not(.mdc-switch--disabled) .mdc-switch__handle {
    --mat-switch-pressed-handle-size: #{$switch-handle-size};
  }

  .mdc-switch--selected:active:not(.mdc-switch--disabled) .mdc-switch__handle {
    margin: 0 0 0 10px !important;
  }

  @include slide-toggle-theme-colors('mat-primary', var(--color-primary));
}
