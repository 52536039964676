@mixin eon-one-theme {
  --color-primary: #1ea2b1;
  --color-primary-active: #2b91a3;
  --color-primary-inactive: #74c3cc;
  --color-lightgray: #f6f6f7;
  --color-gray: #e8e8e8;
  --color-gray-strong: #cccbca;
  --color-darkgray: #8f9192;
  --color-white: #ffffff;
  --color-black: #262626;
  --color-danger: #ea1b0a;
  --color-danger-active: #9d1207;
}
