/* Reset all */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}

/* Body reset */
body {
  line-height: 1;
  margin: 0;
  font-family: 'EONBrixSans', arial, geneva, helvetica, sans-serif;
  color: var(--color-black);
  background-color: var(--color-white);
}

/* List reset */
ol,
ul {
  list-style: none;
}

/* Link reset */
a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;

  &:visited {
    color: inherit;
  }
}

/* Table reset */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Form elements reset */
button,
input,
select,
textarea {
  font-family: inherit; /* Inherit font */
  font-size: inherit; /* Inherit font size */
  margin: 0; /* Reset margin */
}

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer; /* Show pointer cursor on buttons */
}

/* Image reset */
img {
  height: auto;
  display: block;
}

/* Added to remove default tap highlight on touch devices */
a,
button {
  -webkit-tap-highlight-color: transparent;
}

/* Miscellaneous reset */
strong {
  font-weight: bold;
}

em {
  font-style: italic;
}
