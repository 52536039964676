@use 'variables';

.dso-icon {
  width: variables.$icon-size-default !important;
  height: variables.$icon-size-default !important;

  &--small {
    width: variables.$icon-size-small !important;
    height: variables.$icon-size-small !important;
  }

  &--big {
    width: variables.$icon-size-big !important;
    height: variables.$icon-size-big !important;
  }
}
