// Margin Utilities
@mixin generate-margin-utilities($prefix, $directions) {
  @each $direction, $properties in $directions {
    @each $size, $value in (0: 0, 1: 0.25rem, 2: 0.5rem, 3: 1rem, 4: 2rem) {
      @if type-of($properties) == 'list' {
        .#{$prefix}m#{$direction}-#{$size} {
          @each $property in $properties {
            margin-#{$property}: #{$value};
          }
        }
      } @else {
        .#{$prefix}m#{$direction}-#{$size} {
          margin-#{$properties}: #{$value};
        }
      }
    }
  }
}

$margin-directions: (
  '': '',
  // all sides
  't': 'top',
  // top
  'r': 'right',
  // right
  'b': 'bottom',
  // bottom
  'l': 'left',
  // x-axis (left and right)
  'x':
    (
      'left',
      'right',
    ),
  // y-axis (top and bottom),
  'y':
    (
      'top',
      'bottom',
    ),
);

@include generate-margin-utilities('one-', $margin-directions);

// Padding Utilities
@mixin generate-padding-utilities($prefix, $directions) {
  @each $direction, $property in $directions {
    @each $size, $value in (0: 0, 1: 0.25rem, 2: 0.5rem, 3: 1rem, 4: 2rem) {
      .#{$prefix}p#{$direction}-#{$size} {
        padding-#{$property}: #{$value};
      }
    }
  }
}

$padding-directions: (
  '': '',
  // all sides
  't': 'top',
  // top
  'r': 'right',
  // right
  'b': 'bottom',
  // bottom
  'l': 'left',
  // left
  'x': 'left',
  // x-axis (left and right)
  'y': 'top' // y-axis (top and bottom),,
);

@include generate-padding-utilities('one-', $padding-directions);

// Gap Utilities
@mixin generate-gap-utilities($prefix) {
  @each $size, $value in (0: 0, 1: 0.25rem, 2: 0.5rem, 3: 1rem, 4: 2rem) {
    .#{$prefix}gap-#{$size} {
      gap: #{$value};
    }
  }
}

@include generate-gap-utilities('one-');

// Flex Utilities
.one-flex {
  display: flex;
}

.one-flex-row {
  flex-direction: row;
}

.one-flex-column {
  flex-direction: column;
}

.one-justify-start {
  justify-content: flex-start;
}

.one-justify-center {
  justify-content: center;
}

.one-justify-end {
  justify-content: flex-end;
}

.one-justify-between {
  justify-content: space-between;
}

.one-justify-around {
  justify-content: space-around;
}

.one-justify-evenly {
  justify-content: space-evenly;
}

.one-align-start {
  align-items: flex-start;
}

.one-align-center {
  align-items: center;
}

.one-align-end {
  align-items: flex-end;
}

.one-align-stretch {
  align-items: stretch;
}

.one-align-baseline {
  align-items: baseline;
}

.one-flex-wrap {
  flex-wrap: wrap;
}

.one-display-none {
  display: none;
}

.one-white-space-nowrap {
  white-space: nowrap;
}

.one-white-space-normal {
  white-space: normal;
}
